import type { SVGProps } from '@/types/common.types';

const FavoriteSVG = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 22 19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8398 5.02887C21.2133 1.99326 18.9731 0.0365736 16.0875 0.0365736C14.4738 0.0365736 12.7272 0.676612 11.0186 1.88354C9.29103 0.640038 7.54446 0 5.91179 0C3.02615 0 0.767 1.97498 0.159497 5.04716C-0.751758 9.60058 2.17185 15.6535 10.7338 18.9451C10.8288 18.9817 10.9237 19 11.0186 19C11.1135 19 11.2085 18.9817 11.3034 18.9451C19.8464 15.5804 22.751 9.54572 21.8398 5.02887Z"
      />
    </svg>
  );
};

export default FavoriteSVG;
