import { memo } from 'react';

import clsx from 'clsx';

import styles from '@/components/Card/CardTitle.module.scss';
import { TruncatedText, type TruncatedTextProps } from '@/components/TruncatedText';

export const CardTitle = memo((props: TruncatedTextProps) => {
  const { children, className } = props;

  if (props.as === 'link') {
    return (
      <TruncatedText as={'link'} href={props.href!} className={clsx(styles.cardTitle, className)}>
        {children}
      </TruncatedText>
    );
  }

  return (
    <TruncatedText as={props.as || 'h3'} className={clsx(styles.cardTitle, className)}>
      {children}
    </TruncatedText>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  CardTitle.displayName = 'CardTitle';
}
