import type { ClassNameProps } from '@/types/common.types';

const PlaySVG = ({ className }: ClassNameProps) => {
  return (
    <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M32 2C15.4317 2 2 15.4314 2 32C2 48.5686 15.4317 62 32 62C48.5683 62 62 48.5686 62 32C62 15.4314 48.5683 2 32 2Z"
        stroke="#ffff4d"
        strokeWidth="3"
      />
      <path
        d="M42.3687 33.5901L27.3688 42.9651C27.0652 43.1546 26.7201 43.2499 26.375 43.2499C26.0623 43.2499 25.7492 43.1721 25.4659 43.0146C24.8698 42.6841 24.5 42.0569 24.5 41.3749V22.6249C24.5 21.9429 24.8698 21.3157 25.4659 20.9852C26.0619 20.6529 26.7907 20.673 27.3688 21.0346L42.3687 30.4096C42.9167 30.753 43.25 31.3536 43.25 31.9999C43.25 32.6462 42.9167 33.2469 42.3687 33.5901Z"
        fill="#ffff4d"
      />
    </svg>
  );
};

export default PlaySVG;
