import { ClassNameProps } from '@/types/common.types';

import type { Movie } from '@/api/types/movie.types';

import { Card, CardBody, CardTitle, CardSubtitle } from '@/components/Card';
import { ForwardedLink } from '@/components/ForwardedLink';
import styles from '@/components/MovieCard/MovieCard.module.scss';
import { MovieCardImageContainer } from '@/components/MovieCard/MovieCardImageContainer';

// =================================================================

export interface MovieCardProps extends ClassNameProps {
  movie: Movie;
  isFavorite?: boolean;
  isRemovable?: boolean;
  onToggled?: () => void;
  onRemoved?: () => void;
}

// =================================================================

export const MovieCard = (props: MovieCardProps) => {
  const { className, movie, ...rest } = props;

  const {
    module_id,
    id,
    title,
    genres,
    year,
    is_serial,
    poster,
    card_type,
    age_limit,
    is_free,
    is_new,
  } = movie;
  const genre = genres[0]?.title;
  const genreId = genres[0]?.id;

  return (
    <Card className={className}>
      <MovieCardImageContainer
        isSerial={is_serial}
        title={title}
        poster={poster}
        moduleId={module_id}
        movieId={id}
        cardType={card_type}
        ageLimit={age_limit}
        isNew={is_new}
        isFree={is_free}
        {...rest}
      />
      <CardBody>
        <CardTitle as="link" href={`/watch/${module_id}/${id}`}>
          {title}
        </CardTitle>
        <CardSubtitle>
          <ForwardedLink href={`/year/${year}`} className={styles.extraLink}>
            {year}
          </ForwardedLink>
          {genre ? ' / ' : ''}
          <ForwardedLink href={`/genre/${genreId}`} className={styles.extraLink}>
            {genre}
          </ForwardedLink>
        </CardSubtitle>
      </CardBody>
    </Card>
  );
};
