import type { ReactNode, ElementType } from 'react';

import cn from 'classnames';

import styles from '@/components/SectionHeading/SectionHeading.module.scss';

// =================================================================

interface SectionHeadingProps {
  as?: ElementType;
  children: ReactNode;
  className?: string;
}

// =================================================================

export const SectionHeading = (props: SectionHeadingProps) => {
  const { as: Element = 'h2', children, className } = props;

  return <Element className={cn(styles.sectionHeading, className)}>{children}</Element>;
};
