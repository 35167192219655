'use client';

import { memo, useEffect, Fragment, type MouseEventHandler } from 'react';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { breakpoints } from '@/app.config';
import AmediatekaSVG from '@/icons/AmediatekaSVG';
import FavoriteSVG from '@/icons/FavoriteSVG';
import MegogoSVG from '@/icons/MegogoSVG';
import StartTvSVG from '@/icons/StartTvSVG';
import TrashbinSVG from '@/icons/TrashbinSVG';
import WinkSVG from '@/icons/WinkSVG';

import { useCardRemove } from '@/components/MovieCard/useCardRemove';
import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useFavorite } from '@/hooks/useFavorite';

import { MovieCardTypeEnum } from '@/enums/movie-card-type.enums';

import { FadeTransition } from '@/components/Animations';
import { Badge } from '@/components/Badge';
import { IconButton } from '@/components/Buttons';
import { useCardHover, CardImage, CardOverlay } from '@/components/Card';
import { MovieCardProps } from '@/components/MovieCard';
import styles from '@/components/MovieCard/MovieCard.module.scss';
import { Spinner } from '@/components/Spinner';

// =================================================================

const CARD_ASPECT_RATIO = 333 / 500;

// =================================================================

interface MovieCardImageContainerProps
  extends Pick<MovieCardProps, 'isFavorite' | 'isRemovable' | 'onToggled' | 'onRemoved'> {
  isSerial: boolean;
  poster: string;
  title: string;
  moduleId: number;
  movieId: number;
  cardType: MovieCardTypeEnum;
  ageLimit: number;
  isFree: boolean;
  isNew: boolean;
}

// =================================================================

export const MovieCardImageContainer = memo((props: MovieCardImageContainerProps) => {
  const {
    isRemovable,
    isSerial,
    poster,
    title,
    moduleId,
    movieId,
    cardType,
    ageLimit,
    isFree,
    isNew,
    onToggled,
    onRemoved,
    isFavorite: isInitiallyFavorite,
  } = props;

  const t = useTranslations('common');

  const { ref, isHovered } = useCardHover<HTMLDivElement>();

  const { userAccessToken } = useVerification();
  const isAuthenticated = Boolean(userAccessToken);

  const { status, isFavorite, toggle, debouncedFetchFavoriteStatus, debouncedSetLoading } =
    useFavorite({ moduleId, movieId, isFavorite: isInitiallyFavorite });

  const handleToggleFavorite: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    toggle({
      onToggled,
    });
  };

  const { isRemoving, remove } = useCardRemove({
    deepRemove: true,
  });
  const handleRemove: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    remove({ moduleId, movieId, onRemoved });
  };

  const isFavoriteButtonVisible = status === 'toggling' || status === 'ready';

  useEffect(() => {
    if (isAuthenticated && !isInitiallyFavorite && isHovered && !isFavoriteButtonVisible) {
      debouncedSetLoading();
      debouncedFetchFavoriteStatus();
    }
    return () => {
      debouncedSetLoading.cancel();
      debouncedFetchFavoriteStatus.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, isFavoriteButtonVisible, isAuthenticated, isInitiallyFavorite]);

  return (
    <div ref={ref} className={styles.imageContainer}>
      {isSerial && (
        <Fragment>
          <span
            style={{ backgroundImage: `url(${poster})` }}
            className={clsx({
              [styles.stackImage]: true,
              [styles.stackImageRear]: true,
              [styles.stackImageHovered]: isHovered,
            })}
          />
          <span
            style={{ backgroundImage: `url(${poster})` }}
            className={clsx({
              [styles.stackImage]: true,
              [styles.stackImageMiddle]: true,
              [styles.stackImageHovered]: isHovered,
            })}
          />
        </Fragment>
      )}
      <CardImage
        as="link"
        href={`/watch/${moduleId}/${movieId}`}
        src={poster}
        alt={title}
        aspectRatio={CARD_ASPECT_RATIO}
        className={clsx({
          [styles.cardImgHovered]: isHovered,
        })}
        sizes={`50vw, ${breakpoints.small} 33vw, ${breakpoints.medium} 25vw, ${breakpoints.large} 17vw`}
      >
        {(isFree || isNew) && (
          <ul className={styles.annotationsBadgesContainer}>
            {isFree && (
              <Badge as="li" variant="free" position="right">
                {t('free')}
              </Badge>
            )}
            {isNew && (
              <Badge as="li" variant="new" position="right" className="mt-1">
                {t('new')}
              </Badge>
            )}
          </ul>
        )}
        {cardType === MovieCardTypeEnum.AMEDIATIKA && (
          <span className={styles.contentOriginBadgeContainer}>
            <AmediatekaSVG />
          </span>
        )}
        <span className={styles.contentOriginBardgeMoreTv}>
          {cardType === MovieCardTypeEnum.MEGOGO && <MegogoSVG />}
          {cardType === MovieCardTypeEnum.MORETV && <WinkSVG width={70} height={30} />}
          {cardType === MovieCardTypeEnum.START && <StartTvSVG width={70} height={30} />}
        </span>
        {ageLimit > 0 && <span className={styles.ageLimit}>{ageLimit}+</span>}
        <FadeTransition inProp={isHovered}>
          <CardOverlay>
            {isAuthenticated && status === 'loading' && <Spinner className={styles.spinner} />}
            {isAuthenticated && isFavoriteButtonVisible && (
              <IconButton
                as="button"
                icon={FavoriteSVG}
                iconWidth={25}
                iconHeight={25}
                title={isFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
                onClick={handleToggleFavorite}
                disabled={status === 'toggling'}
                className={clsx({
                  [styles.favoriteButton]: true,
                  [styles.active]: isFavorite,
                })}
              />
            )}
            {isAuthenticated && isRemovable && (
              <IconButton
                as="button"
                icon={TrashbinSVG}
                iconWidth={25}
                iconHeight={25}
                title="Удалить из истории"
                onClick={handleRemove}
                disabled={isRemoving}
                className={clsx({
                  [styles.deleteButton]: true,
                  [styles.active]: isFavorite,
                })}
              />
            )}
          </CardOverlay>
        </FadeTransition>
      </CardImage>
    </div>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  MovieCardImageContainer.displayName = 'MovieCardImageContainer';
}
