import { JSX } from 'react';

import clsx from 'clsx';

import PlaySVG from '@/icons/PlaySVG';
import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/Card/CardOverlay.module.scss';

interface CardOverlayProps extends Partial<ChildrenProps>, ClassNameProps {
  icon?: (props: ClassNameProps) => JSX.Element;
  iconClassName?: string;
}

export const CardOverlay = (props: CardOverlayProps) => {
  const { children, className, iconClassName, icon: IconElement = PlaySVG } = props;

  return (
    <div className={clsx(styles.cardOverlay, className)}>
      <IconElement className={clsx(styles.cardOverlayIcon, iconClassName)} />
      {children}
    </div>
  );
};
